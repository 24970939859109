<template>
    <div>     
        <div class="text-right">
          <b-form v-if="showform">
            <b-form-textarea
                
                :label="$t('Comment')"
                v-model="form.comment"
                rows="2"
                max-rows="3"
                @input="canCommentStatus()"
            > </b-form-textarea>
             <b-button class="mt-3 mb-3" :disabled="canComment" @click="comment()" type="button" variant="primary">{{$t('Comment')}}</b-button>
            </b-form>
        </div>
        <p v-if="!showform" class="text-right mt-2 mb-3">التعليق قيد المراجعة</p>

        <b-media class="border-bottom text-right" style="width:100%" v-for="comment in comments" :key="'comment'+comment.id" right-align vertical-align="center">
   
            <h5 class="mt-2 mb-2 "><span v-if="comment.user">{{comment.user.name }}</span><span v-if="!comment.user">زائر</span></h5>
            <p class="mb-3 mt-3">
            {{comment.comment}}
            </p>
        </b-media>
    </div>
</template>


<script>
import axios from 'axios'
export default {
    props: ['post_id', 'comments','canSub'],
    name: 'CommentForm',
    data() {
      return {
          form: {
            comment: "",
          },
          canComment: true,
          showform:true,
      }
    },     
    methods:{
        comment(){
           //alert('sadas');
           
           this.onSubmit();

        },
        /************************************ */
         canCommentStatus(){
            
            if(  this.form.comment == ""){
                this.canComment = true;
            }else{
                this.canComment = false;
            }
         }  ,

  
        /*********************************** */
        onSubmit() {
      
      {

        this.isSendingForm = true;
        this.ShowError=false;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
      
        axios.post(this.$store.state.backendUrl+'api/ar/comment/'+this.post_id,formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
              
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  this.rate = response.data.success.rate;
                  this.$store.commit('saveComments',this.post_id);
                  this.form.comment = "";
                  this.showform=false;
                  setTimeout(()=>{     
                


                  }, 3000);
                
                
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
             
            });
           }
      },
    }
}
</script>