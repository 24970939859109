<template>
    <div>   
       <ErrorAlert :msg="errorMgs" :visible="ShowError" />  
        <div >
            <b-button :disabled="cantVote" @click="voting('up')" variant="primary"><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon></b-button>
             <b-badge class="ml-3 mr-3" variant="light">{{rate}} </b-badge>
            <b-button :disabled="cantVote"  @click="voting('down')" variant="primary"><b-icon icon="hand-thumbs-down"  aria-hidden="true"></b-icon></b-button>
        </div>
    </div> 
</template>


<script>
import axios from 'axios'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
export default {
    props: ['post_id', 'rate','canSub'],
    name: 'VotingForm',
    data() {
      return {
          form: {
            vote: null,
          },
          cantVote:false,
          errorMgs:'',
          ShowError: false,
      }
    },     
    components:{
    ErrorAlert
        
    },
    methods:{
        voting(isVote){
           this.form.vote =isVote;
           this.onSubmit();
           

        },
        /************************************ */
        onSubmit() {
      
      {

        this.isSendingForm = true;
        this.ShowError=false;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
      
        axios.post(this.$store.state.backendUrl+'api/ar/voting/'+this.post_id,formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
              
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  this.rate = response.data.success.rate;
                  this.$store.commit('saveVotes',this.post_id);
                  this.cantVote = true;
                  setTimeout(()=>{     
                


                  }, 3000);
                
                
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
             
            });
           }
      },
    },
 /********************* */
    mounted() {
      console.log('>>>>>>>>>>>>' +this.canSub)
      if(this.canSub){
   if(this.$store.state.votes.includes(this.post_id) ){  
         this.cantVote = true;     
    }}
     
      
      //this.form.lang_id = this.$i18n.locale;
    }

}
</script>