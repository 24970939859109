<template>
    <div>
    <ErrorAlert :msg="errorMgs" :visible="ShowError" />   
 <b-container  class="p-0" >
      <b-row class="mt-5 text-right">
         <b-col cols="12" lg="12" xl="12" > 
     <span>{{$t('Published by')}}: </span>      <router-link :to="'/user_posts/'+post.user.id">{{post.user.name}}</router-link>
           </b-col>
    </b-row>
     
    <b-row >
      
      <b-col cols="12" lg="12" xl="12" class="mt-5" > 

        <h1>{{post.name}}</h1>
      </b-col>
    </b-row>
   
    <b-row class="mt-5">
      <b-col cols="12"  class="text-center">
        <youtube width="100%"   :video-id="videoId"></youtube>
      </b-col>
    <b-col cols="12"  class="text-center">
      <VotingForm  :canSub="canSub" :post_id="post.id"  :rate="post.rate"   />
    </b-col>
    </b-row>
   
    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
       <div v-html="post.description" class="text-right"></div>

      </b-col>
    </b-row>
<b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
      <h3 class="text-right">التعليقات</h3> 
       </b-col>
    </b-row>
    <b-row class="mt-5">

    
       <CommentForm :canSub="canSub" :post_id="post.id"  :comments="comments"   />
        
    </b-row>
    


   <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
               
             <b-nav class="shareBTNS" >
                 <b-nav-item> شارك : </b-nav-item>
            <b-nav-item  :href="'https://www.facebook.com/sharer/sharer.php?u='+$store.state.baseUrl+'competition/'+post.slug" target="_blank"><b-icon icon="facebook" scale="1" shift-v="1"  aria-hidden="true"></b-icon></b-nav-item>
            <b-nav-item  :href="'https://twitter.com/intent/tweet?text='+post.name+' '+$store.state.baseUrl+'competition/'+post.slug" target="_blank"><b-icon icon="twitter" scale="1" shift-v="1" aria-hidden="true"></b-icon></b-nav-item>
     
            </b-nav>
      </b-col>
    </b-row>


</b-container>

    </div>
</template>

<script>
import axios from 'axios'
//import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import VotingForm from '@/components/post/VotingForm.vue'
import CommentForm from '@/components/post/CommentForm.vue'
import moment from "moment";
import {getIdFromUrl } from 'vue-youtube'
  export default {
      name:'Competitions',
    data() {
      return {
        moment: function () {
        return moment();
        },
        showContent: false,
        path:this.$store.state.backendUrl+"api/ar/competitor_show/"+this.$route.params.id,
        post:[],
        videoId:null,
        comments:[],
        videoLink:"",
        subLink:"",
        errorMgs:'',
        ShowError: false,
        canSub:null,
      }
    },
    components:{
     ErrorAlert, VotingForm,CommentForm
        
    }
    ,
    methods: {
      routerTo(link){
        if(link == 'videos'){ this.$router.push(this.videoLink)}
        if(link == 'subs'){this.$router.push(this.subLink)}

      },
  
           getCreateData(){
           this.showContent =false;
           axios.get(this.path, {params:this.form,
            headers: {
          // 'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
              console.log(response);
              this.post = response.data.success.post;
               this.comments = response.data.success.comments;
               this.canSub = response.data.success.canSub;
               this.videoId =  getIdFromUrl(this.post.url) ;
               this.showContent =true;
              
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } ,
 
    },
    filters: {
      moment: function (date) {
        return moment(date).format('DD-MM-YYYY');
      }
      },
     /*********************** */
    mounted(){
     
     this.getCreateData();
    }
  }
</script>